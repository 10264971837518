<template>
	<div class="mask-page">
		<div class="main" :style="mainBg">
			<img v-if="type == 0" class="tips-img" src="@/assets/images/blindbox/title_01.png" alt="">
			<img v-else class="tips-img" src="@/assets/images/blindbox/title_02.png" alt="">
			<p class="tips">开启盲盒100%中奖<br>盲盒礼物从池中选取</p>
			<div class="item-list">
				<div v-for="(item, index) in prizePool" :key="index" class="item-box">
					<div class="item">
						<div class="img-box">
							<img :src="imgHost+item.prizeUrl" alt="">
						</div>
					</div>
				</div>
			</div>
			<div @click="goBack()" class="btn-box">
				<img src="@/assets/images/blindbox/btn_02.png" alt="">
			</div>
		</div>
		<div v-if="loading" class="loading">
			<van-loading color="#d1af72" size="40" />
		</div>
	</div>
</template>

<script>
	import { prizePoolApi } from '@/request/api'
	export default {
		data() {
			return {
				loading: false,
				mainBg: {
					backgroundImage: `url(${require("@/assets/images/blindbox/gift-bj.png")})`,
					backgroundSize: '100%, 100%'
				},
				type: '',
				lotteryId: '',
				propsId: '',
				prizePool: [],
				imgHost: process.env.VUE_APP_IMG_URL
			}
		},
		methods: {
			// 奖池列表
			getPrizeList() {
				this.loading = true
				prizePoolApi({ lotteryId: this.lotteryId, propsId: this.propsId}).then(res => {
					this.loading = false
					this.prizePool = res.data
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			goBack() {
				this.$router.back()
			}
		},
		mounted() {
			this.type = this.$route.query.type
			this.lotteryId = this.$route.query.lotteryId
			this.propsId = this.$route.query.propsId
			this.getPrizeList()
		}
	}
</script>

<style lang="stylus" scoped>
.mask-page
	position absolute
	top 0
	left 0
	display flex
	flex-direction column
	justify-content center
	align-items center
	padding 0 0.746666rem
	box-sizing border-box
	width 100%
	height 100%
	.loading 
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		z-index 10
		display flex
		justify-content center
		align-items center
		background rgba(0, 0, 0, 0.5)
	.main
		position relative
		width 100%
		height 14.4rem
		.tips-img 
			position absolute
			left -0.533333rem
			top 2px
			width 6.56rem
			height 1.74rem
		.tips
			margin 1.866666rem 0 0 0.666666rem
			font-size 0.4rem
			color #907357
			font-weight 600
			text-align left
			line-height 0.55rem
		.item-list 
			position relative
			display flex
			flex-wrap wrap
			justify-content flex-start
			margin 0.05rem 0.533333rem 0.2rem
			min-height 8rem
			.item-box 
				position relative
				width 25%
				padding-top 25%
				.item 
					position absolute
					top 0
					left 0
					box-sizing border-box
					padding 0.133333rem
					width 100%
					height 100%
					.img-box
						box-sizing border-box
						padding 0.133333rem
						width 100%
						height 100%
						border 1px solid #B5A585
						background #E3DAC4
						img
							width 100%
							height 100%
		.btn-box 
			position absolute
			bottom 0.266666rem
			left 50%
			transform translate(-50%, 0)
			font-size 0
			img 
				width 5.333333rem
				height 1.546666rem
</style>
